import Button from "@/components/button";
import ChartLine from "./components/chart-line";
import Dropdown from "@/components/dropdown";
import RankTable from "./components/rank-table";
import Stat from "./components/stat";

export default {
    name: "neo-dashboard",
    components: {
        "neo-dropdown": Dropdown,
        "neo-button": Button,
        "neo-stat": Stat,
        "neo-chart-line": ChartLine,
        "neo-rank-table": RankTable,
    },
    props: [],
    data() {
        return {
            stats: [
                {
                    label: "Completed",
                    value: "1.2k",
                    info: "345",
                    color: "var(--color-success)",
                },
                {
                    label: "Pending",
                    value: "4.5k",
                    info: "345",
                    color: "var(--color-alert)",
                },
                {
                    label: "Risk Identified",
                    value: "800k",
                    info: "345",
                    color: "var(--color-danger)",
                },
                {
                    label: "Case Cancelled",
                    value: "9.2k",
                    info: "345",
                    color: "var(--color-inter)",
                },
            ],
        };
    },
    computed: {},
    mounted() {},
    methods: {},
};
