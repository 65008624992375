import Chart from "chart.js";

export default {
    name: "neo-chart-line",
    components: {},
    props: [],
    data() {
        return {
            id: "line-chart",
            width: null,
            height: null,
            chartData: {
                type: "line",

                data: {
                    datasets: [
                        {
                            label: "Completed",
                            data: [10, 30, 40, 70, 50, 20, 40, 50, 70, 75, 90, 10],
                            borderColor: "#0d69d5",
                            backgroundColor: "transparent",
                        },
                        {
                            label: "Working",
                            data: [0, 59, 75, 20, 10, 50, 20, 55, 80, 14, 70, 20],
                            borderColor: "#dfa30b",
                            backgroundColor: "transparent",
                        },
                    ],

                    labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
                },
                options: {
                    tooltips: {
                        enabled: true,
                    },
                    elements: {},
                    legend: {
                        display: false,
                    },
                    responsive: true,
                    lineTension: 3,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    display: true,
                                    fontFamily: "var(--brand-font)",
                                    fontSize: 11,
                                },
                                gridLines: {
                                    display: true,
                                },
                            },
                        ],
                        xAxes: [
                            {
                                ticks: {
                                    display: true,
                                    fontFamily: "var(--brand-font)",
                                    fontSize: 11,
                                },
                                gridLines: {
                                    display: false,
                                },
                            },
                        ],
                    },
                },
            },
        };
    },
    computed: {},
    mounted() {
        const el = this.$refs.lineChart;
        this.width = el.clientWidth;
        this.height = el.clientHeight;
        this.createChart(this.id, this.chartData);
    },
    methods: {
        createChart(chartId, chartData) {
            const ctx = document.getElementById(chartId);

            new Chart(ctx, {
                type: chartData.type,
                data: chartData.data,
                options: chartData.options,
            });
        },
    },
};
